/*

NOTE: tag is not supported in horizontal menu

Array of object

Top level object can be:
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- nav Grp (top level grp is called header group) ---/

title
icon (if it's on top/second level)
children

/--- nav Item (top level item is called header link) ---/

icon (if it's on top/second level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)

*/
/*
import dashboard from './dashboard'
import hummz from './hummz'
import apps from './apps'
import pages from './pages'
import chartsAndMaps from './charts-and-maps'
import uiElements from './ui-elements'
import formAndTable from './forms-and-table'
import others from './others'

// Array of sections
export default [...dashboard, ...hummz, ...apps, ...uiElements, ...formAndTable, ...pages, ...chartsAndMaps, ...others]
*/
import rootState from '../../store'
// console.log('navigation:rootState', rootState.state.navigation)
const navigation = rootState.state.navigation.navigation
export default  navigation

// export default [
//     {
//       title: 'Reception',
//       route: 'reception',
//       icon: 'HomeIcon',
//     },
//     {
//       title: 'Stage',
//       route: 'stage',
//       icon: 'VideoIcon',
//     },
//     {
//       title: 'Sessions',
//       route: 'sessions',
//       icon: 'CalendarIcon',
//     },
//     {
//       title: 'Speakers',
//       route: 'speakers',
//       icon: 'UsersIcon',
//     },
//     {
//       title: 'Sponsors & Organizers',
//       route: 'exhibition',
//       icon: 'GridIcon',
//     },
//     {
//       title: 'Partners',
//       route: 'partners',
//       icon: 'ApertureIcon',
//     },
//     {
//       title: 'Attendees',
//       route: 'attendees',
//       icon: 'UsersIcon',
//     },
//     {
//       title: 'Contests',
//       route: 'contests',
//       icon: 'AwardIcon',
//     },
//     // {
//     //   title: 'Networking',
//     //   route: 'apps-chat',
//     //   icon: 'MessageCircleIcon',
//     // },
//   ]