<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <!-- <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div> -->

    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <b-img v-if="event.logo" :src="event.logo" :alt="event.title" :title="event.title" height="40px" class="mr-1"/>
      <b-img v-else-if="event.organizer.logo" :src="event.organizer.logo" :alt="event.organizer.name" :title="event.organizer.name" height="40px" class="mr-1"/>
      <h3 class="m-0">{{event.title}}</h3>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- <dark-Toggler class="d-none d-lg-block" /> -->
      <notification-dropdown />
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{userData.name}}
            </p>
            <span class="user-status">{{userData.designation || ''}}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="userData.avatar"
            :text="avatarText(userData.name)"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item link-class="d-flex align-items-center" :to="{ name: 'user-profile'}">
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="HeadphonesIcon"
            class="mr-50"
          />
          <span>Support</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BImg, BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import store from '@/store/index'

import { initialAbility } from '@/libs/acl/config'
import { avatarText } from '@core/utils/filter'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import NotificationDropdown from './NotificationDropdown.vue'

export default {
  components: {
    BImg,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  
    // Navbar Components
    DarkToggler,
    NotificationDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
   data() {
    return {
      avatarText,
      userData: store.getters['account/getUserData'],
      event: store.getters['event/getEvent'],
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('account/logout')
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      // this.$store.commit('account/REMOVE_AUTH_TOKEN')
      // this.$store.commit('account/UPDATE_USER', {})

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
  },
}
</script>
