<template>
  <layout-horizontal>

    <router-view :key="this.$route.path" />

    <template #navbar="{ toggleVerticalMenuActive }">
      <!-- <app-navbar-horizontal-layout-brand /> -->
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>

    <app-footer slot="footer" />

    <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    />
  </layout-horizontal>

</template>

<script>
import LayoutHorizontal from '@core/layouts/layout-horizontal/LayoutHorizontal.vue'
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import AppNavbarHorizontalLayoutBrand from '@core/layouts/components/app-navbar/AppNavbarHorizontalLayoutBrand.vue'
import Navbar from '../components/Navbar.vue'
import AppFooter from "@core/layouts/components/AppFooter.vue";
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    LayoutHorizontal,
    AppCustomizer,
    AppNavbarHorizontalLayoutBrand,
    Navbar,
    AppFooter,
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
    }
  },
}
</script>
